<template>
  <!-- 忘记密码 -->
  <div class="admin-account">
    <div class="admin-box">
      <div class="admin-box-login">
        <span>企业管理号：</span>
        <div class="admin-box-login-div">
          <el-input type="text" placeholder="请输入内容" v-model="figure" maxlength="18" show-word-limit>
          </el-input>
        </div>
      </div>
      <div class="admin-box-login">
        <span>手机号：</span>
        <div class="admin-box-login-div">
          <el-input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号" maxlength="11" v-model="phnoe"></el-input>
        </div>
      </div>
      <div class="admin-box-login admin-box-phnoeYz">
        <span>验证码：</span>
        <div class="admin-box-login-div">
          <input type="text" placeholder="输入验证码" maxlength="4" v-model="phnoeYz">
          <el-button type="button" @click="handlecode()">{{iscode? sj+'s' : '发送验证码' }}
          </el-button>
        </div>
      </div>
      <div class="admin-box-login">
        <span>新密码：</span>
        <div class="admin-box-login-div">
          <input :type="mimaS" placeholder="请输入您的密码" v-model="passwordSx">
          <i @click="eyeS" class="gai"><img :src="suoS?require('../../../assets/img/bi.png'):require('../../../assets/img/zheng.png')" alt=""></i>
        </div>
      </div>
      <div class="admin-box-login">
        <span>再次确认：</span>
        <div class="admin-box-login-div">
          <input :type="mima" placeholder="请输入您的密码" v-model="passwordS">
          <i @click="eyeX" class="gai"><img :src="suo?require('../../../assets/img/bi.png'):require('../../../assets/img/zheng.png')" alt=""></i>
        </div>
      </div>
      <div class="admin-button">
        <div class="admin-button-box">
          <a @click="register()">确 定</a>
        </div>
      </div>
      <div class="admin-registerY">
        <span class="admin-registerY-span">已有账号，马上</span><span @click="handlelogin()">登录</span><i></i>
      </div>
    </div>
  </div>
</template>

<script>
import { forget_ent_acct_pwd, ent_sms } from "../../../api/login"
import { ent_acct_login } from '../../../api/foodqiye'
export default {
  data () {
    return {
      mima: 'password',
      mimaS: 'password',
      figure: '',
      phnoe: '',
      phnoeYz: '',
      password: '',
      passwordS: '',
      passwordSx: '',
      sj: '',
      iscode: false,
      suo: true,
      suoS: true
    }
  },
  methods: {
    // 密码显示隐藏
    eyeX () {
      this.suo = !this.suo
      if (this.suo) {
        this.mima = 'password'
      } else {
        this.mima = 'text'
      }
    },
    eyeS () {
      this.suoS = !this.suoS
      if (this.suoS) {
        this.mimaS = 'password'
      } else {
        this.mimaS = 'text'
      }
    },

    //倒计时
    dao () {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.sj = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.sj > 0 && this.sj <= TIME_COUNT) {
            this.sj--;
            this.iscode = true
          } else {
            clearInterval(this.timer)
            this.timer = null
            this.sj = 0
            this.iscode = false
          }
        }, 1000)
      }
    },
    //返回登陆
    handlelogin () {
      this.$router.push('/AdminAccount')
    },
    //发送验证码
    handlecode () {
      if (/^1[3456789]\d{9}$/.test(this.phnoe) && this.figure.length == 18) {

        if (this.phnoe != '' && this.figure != '') {
          this.sms_a()
        } else {
          this.$message({
            message: '手机号码或企业管理号为空，请填写',
            type: 'warning'
          })
        }

      } else {
        this.$message({
          message: '手机号码或企业管理号有误，请重填',
          type: 'warning'
        })
      }
    },
    sms_a () {
      ent_sms({
        data: { mobile: this.phnoe, ent_acct: this.figure }
      }).then(res => {
        console.log(res, '99999999999999');
        if (res.code == 10000) {
          this.$message({
            message: '今日以超过最大值',
            type: 'warning'
          })
        } else if (res.code == 10001) {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          })
          this.dao()
        } else if (res.code == 10002) {
          this.$message({
            message: '短信发送失败',
            type: 'error'
          })
        } else if (res.code == 10087) {
          this.$message({
            message: '企业账号与法人手机号不匹配',
            type: 'error'
          })
        }
      })
    },
    // 确定按钮
    register () {

      if (this.passwordSx !== '' && this.passwordS !== '' && this.figure !== '' && this.phnoe !== '' && this.phnoeYz !== '') {
        if (this.passwordSx !== this.passwordS) {
          this.$message({
            message: '密码不一致',
            type: 'error'
          })
        }
        else if (!/^1[3456789]\d{9}$/.test(this.phnoe) && this.figure.length != 18) {
          this.$message({
            message: '手机号码或企业管理号有误，请重填',
            type: 'warning'
          })
        }
        else {
          const pass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/

          if (!pass.test(this.passwordSx)) {
            this.$message({
              message: '请输入6-20位数字与字母组合密码',
              type: 'error'
            })
          } else {


            forget_ent_acct_pwd({
              data: {
                mobile: this.phnoe,         //电话
                sms: this.phnoeYz,            //验证码		
                ent_acct: this.figure,       //企业账号		
                pwd: this.passwordSx         //密码
              }
            }).then(res => {
              if (res.code == 10005) {
                this.$message({
                  message: '验证码有误',
                  type: 'error'
                })
              }
              if (res.code == 10013)

                ent_acct_login({
                  data: {
                    ent_acct: this.figure,
                    ent_pwd: this.passwordSx
                  }
                }).then(ras => {
                  console.log(ras, '修改成功 加载登陆企业');
                  this.$message({
                    message: '正在为您跳转页面，请稍后',
                    type: 'success'
                  })

                  setTimeout(() => {
                    this.$router.push({
                      name: 'Admin',
                      params: {
                        isShows: true,
                        ent_info: ras.body.data.ent_info,
                      }
                    })
                  }, 1000);
                  sessionStorage.setItem('isLogin', 'true')
                  sessionStorage.setItem('ent_info', JSON.stringify(ras.body.data.ent_info.ent_id))
                })

            })


          }

        }
      } else {
        this.$message({
          message: '必填项不能为空',
          type: 'error'
        })
      }
    }
  },
}
</script>

<style scoed lang="scss">
.el-main {
  //
  background: #f6f6fa;
}

.admin-account {
  width: 100%;
  background: #fff;
  padding: 84px 0 100px 0;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
  border-radius: 15px;

  .admin-button {
    width: 600px;
    margin: 0 auto;
    .admin-button-box {
      a {
        width: 450px;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(200deg, #fc4353, #ff5e41);
        border-radius: 10px;
        font-size: 20px;
        text-align: center;
        margin: 0 auto 0 124px;
        display: block;
        color: #fff;
      }
    }
  }
  .admin-box {
    input {
      padding-left: 15px;
      box-sizing: border-box;
    }
    .admin-box-login {
      position: relative;
      display: flex;
      width: 600px;
      margin: 0 auto;
      .admin-box-login-div {
        width: 460px;
        margin: 0 auto;
      }
      & > span {
        width: 110px;
        font-size: 16px;
        display: block;
        text-align: right;
        margin-top: 14px;
        font-weight: 600;
        color: #333;
      }
      i {
        position: absolute;
        right: 31px;
        top: 8px;
      }
    }
    .admin-box-phnoeYz {
      margin: 0 auto 50px auto;
    }
    .admin-registerY {
      display: flex;
      justify-content: center;
      margin: 40px auto 0 320px;
      .admin-registerY-span {
        font-size: 20px;
        color: #c5c5c5;
      }
      & > span {
        font-size: 20px;
        margin-top: 2px;
        color: #fc4f5e;
      }
      i {
        background: url('../../../assets/img/deng.png');
        width: 28px;
        height: 26px;
        display: block;
        margin-left: 10px;
      }
    }
  }
}
</style>
