import axios from '@/utils/request'

/**
 * 获取验证码接口
 * @param {*} sid 唯一标识
 */
// const getCode = (sid) => {
//   // axios.request({
//   //  method: 'get',
//   //  url: '/getCaptcha'
//   // })
//   return axios.get('/public/getCaptcha', {
//     params: {
//       sid: sid
//     }
//   })
// }

/**
 * 找回密码接口
 * @param {} option 用户信息（邮箱，验证码）
 */
const forget = (option) => {
  return axios.post('/login/forget', {
    ...option
  })
}

/**
 * 登录接口
 * @param {*} loginInfo 用户登录信息
 */
const login = (loginInfo) => {
  return axios.post('/login/login', {
    ...loginInfo
  })
}

/**
 * 注册接口
 * @param {*} regInfo 用户注册信息
 */
const reg = (regInfo) => {
  return axios.post('/login/reg', {
    ...regInfo
  })
}

//首次登陆修改企业账号密码
const first_login_alter_ent_acct_pwd = (data) => axios.post('/first_login_alter_ent_acct_pwd', { ...data })
//企业账号：忘记密码--密码重置
const forget_ent_acct_pwd = (data) => axios.post('/forget_ent_acct_pwd', { ...data })
// 发送验证码
const ent_sms = (data) => axios.post('/ent_sms',{ ...data })
// 获取验证码
const getcode = (data) => axios.post('/getCode',{ ...data })

/**
 * 重置密码接口
 * @param {*} info 重置密码信息
 */
const reset = (info) => axios.post('/login/reset', { ...info })

export {
  first_login_alter_ent_acct_pwd,
  forget_ent_acct_pwd,
  ent_sms,
  getcode,
  forget,
  login,
  reg,
  reset
}
